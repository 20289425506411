@import 'material-icons/iconfont/material-icons.css';

/* You can add global styles to this file, and also import other style files */
@import '@fontsource/open-sans';

body,
html {
  height: 100%;
}

body {
  margin: 0;
}

@media print {
  .report-container {
    border: 0 !important;
    box-shadow: none !important;
    padding: 0 !important;
    margin: 0 !important;
    position: absolute;
    background-color: white;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 999999;
  }

  .app-header {
    display: none;
  }

  .mat-sidenav {
    display: none;
  }
}
